import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { propertyDetailUrl, GetPrice, getAgentId } from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";
import { ShowProcessedImage } from 'gatsby-theme-starberry-lomondgroup/src/common/ggfx-client/module/components/show-image';
import imageConfig from "gatsby-theme-starberry-lomondgroup/static/images/config.json"
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import SearchResultsImagePopup from "gatsby-theme-starberry-lomondgroup/src/components/SalesCard/SearchResultsImagePopup"
import { SaveItem } from "@starberry/myaccount-website-utils"
import { HeartIcon } from "gatsby-theme-starberry-lomondgroup/src/common/icons";
import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";
import _ from 'lodash';

import { CurrConvertPrice } from 'gatsby-theme-starberry-lomondgroup/src/components/CurrencyConverter/CurrConvertPrice'
import { useGlobalState } from 'gatsby-theme-starberry-lomondgroup/src/components/GlobalState/GlobalStateProvider';

const ListView = ({ item, img_type, isGridView, userObjects, collections_property, student_property, offices, setViewingLink, setModalShow, show_pppw}) => {

    const { currState } = useGlobalState();
    
    const location = useLocation();

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [propertyItem, setPropertyItem] = useState([]);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var department = item?.department ? item.department : "residential"
 

    var property_id = item.objectID || item.id;
    
    var property_url = propertyDetailUrl(department, item.search_type, item.status, item.slug, property_id);
    //var search_url = PropertySearchUrl(item.department, item.search_type);

    // if(item?.officeDepartment === "investments"){
    //     property_url = `/${PageLinks.contact_investment_centre}/`
    // }

    var search_url = location.pathname;

    var img_transforms = imageConfig.property.images.list_view.sizes;
    var thumb_transforms = imageConfig.property.images.thumnail_image.sizes;

    var alt_text = item.display_address + " - " + process.env.GATSBY_SITE_NAME;


    const ImageRenderList = ({ item, image_url, imagename }) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.images_Transforms) {
            processedImages = item?.imagetransforms?.images_Transforms;
        }
        return (
            <ImageModule ImageSrc={image_url} title={item.display_address} altText={item.display_address} imagetransforms={processedImages} ggfx_results={item?.ggfx_results}  renderer="srcSet" imagename={imagename} strapi_id={property_id} />
        )
    }

    var energyRate = item?.energyRate ? item?.energyRate : item?.extra?.energyRate !== "" ? item?.extra?.energyRate : ""

    var prop_images = item?.images &&  item?.images?.length > 0 ? item?.images?.filter(img => img?.url && img.url !== null) : []

    var office_depart = item?.office_crm_id ? item?.office_crm_id : "";

    // useEffect(() => {
    //     ApiRequest({
    //         method: "GET",
    //         url: `${process.env.GATSBY_STRAPI_SRC}/properties/${property_id}`,
    //         headers: {
    //             Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    //         }
            
    //     }, (result) => {
    //         if (result?._id) {
    //             setPropertyItem(result)
    //         }
    //     })
    // },[])

    //console.log("propertyItem", item.officeDepartment);

    let prop_all_images = propertyItem?.images ? propertyItem?.images : prop_images;

    // console.log("prop_all_images", isOpen, prop_all_images, (prop_all_images && prop_all_images?.length != 0))

    var book_a_viewing_link = process.env.GATSBY_BOOK_VIEWING_LINK;
    var agent_id = getAgentId(item?.office_crm_id);

    if(process.env.GATSBY_BOOK_VIEWING_LINK){
        book_a_viewing_link = book_a_viewing_link.replace('{agent_id}', agent_id).replace('{unique_id}', item?.crm_id)
    }


    let officeContact = _.find(offices, function (o) { return _.includes(_.split(o.office_crm_id, ','), item?.office_crm_id); });
    
    var to_email_id = process.env.GATSBY_BOOK_VIEWING_EMAIL ? process.env.GATSBY_BOOK_VIEWING_EMAIL : officeContact?.email ? officeContact?.email : "";

    var office_mobile = officeContact?.mobile

    if(item?.search_type === "lettings"){
        if(officeContact?.letting_info?.mobile){
            office_mobile = officeContact?.letting_info?.mobile
        }
        if(officeContact?.letting_info?.email){
            to_email_id = officeContact?.letting_info?.email
        }
    } 

    let rental_yield = item?.estimatedMonthlyRetailValue || item?.selling_info?.estimatedMonthlyRetailValue
    ? parseFloat((item?.estimatedMonthlyRetailValue || item?.selling_info?.estimatedMonthlyRetailValue) * 12 /  (item.recommendedPrice || item?.selling_info?.recommendedPrice) * 100).toFixed(2) 
    : 0

    let price_convert = CurrConvertPrice(item.price);

    var pppw = ""
    var month_price = item.price_qualifier === "pw" ? CurrConvertPrice((item.price*52/12).toFixed(2)) : CurrConvertPrice(item.price);
    var week_price = item.price_qualifier === "pcm" ? CurrConvertPrice((item.price*12/52).toFixed(2)) : CurrConvertPrice(item.price);

    if(show_pppw){
        pppw = week_price;
    } else{
        pppw = month_price;
    }


    const RentYieldPrice = () => {
        return(
            item?.officeDepartment === "investments" &&
            (
                process.env.GATSBY_SITE_NAME === "D. J. Alexander" ? 
                <>
                    {/* <span className="sm-text investment">YOA Avg. Rental Price</span>
                    <span className="sm-text investment mb-2">RIOA Avg. Rental Yield</span> */}
                    <span className="sm-text investment"><b>{GetPrice(item?.estimatedMonthlyRetailValue || item?.selling_info?.estimatedMonthlyRetailValue)}</b>&nbsp;Avg. Rental Price</span>
                    {/* <span className="sm-text investment mb-2"><b>{item?.grossYieldPercentage}%</b>&nbsp;Avg. Rental Yield</span> */}
                    <span className="sm-text investment mb-2"><b>{rental_yield}%</b>&nbsp;Avg. Rental Yield</span>
                </>
                :
                <>
                    <span className="sm-text investment"><b>{GetPrice(item?.estimatedMonthlyRetailValue ||  item?.selling_info?.estimatedMonthlyRetailValue)}</b>&nbsp;Avg. Rental Price</span>
                    <span className="sm-text investment mb-2"><b>{item?.grossYieldPercentage ||  item?.selling_info?.grossYieldPercentage}%</b>&nbsp;Avg. Rental Yield</span>
                </>
            )
        )
    }


    return (
        <div className="sales-wrap" id={property_id}>
            <div className="sales-img-wrap">
                <div className={`sales-img  ${item?.images?.length > 1 ? "" : "full_wdth"}`}>
                    {/* <img src={item.images1[0]["570x374"]} alt="img" /> */}
                    <Link to={property_url} state={{ property_data:{...item, address:item.display_address}, form_type: "investment_centre", property_url: property_url, to_email_id, search_url }}>
                        {img_type === "no_property" ?
                            <ImageRenderList item={item} image_url={prop_images[0]}  />
                            :
                            item?.images ? (
                                item?.images.length > 0 ?
                                    <ShowProcessedImage images={item?.images[0]} attr={{ alt: alt_text }} transforms={img_transforms} />
                                :
                                    <ImageRenderList item={item} image_url={item?.images[0]} imagename={"property.images.list_view"} />
                                )
                            :
                                <ImageRenderList item={item} image_url={prop_images[0]}  />
                        }
                    </Link>

                    {item?.officeDepartment === "Prestige" ?
                        <span className="top-text prestige_bg">Hardisty <span className="prestige_clr">Prestige</span></span>

                    : item?.officeDepartment === "Collection" ? (
                            process.env.GATSBY_SITE_NAME === "John Shepherd" ?
                                <span className="top-text collection_bg">John Shepherd <span className="collection_clr">Collection</span></span>
                            :
                                (collections_property ? 
                                    ""
                                :
                                    <span className="top-text collection_bg"><span className="collection_clr">Collection</span></span>
                                )
                        )

                    : ''
                    }

                    {item?.department === "auction" && <span className="top-text auction_bg">Auction</span>}

                    {(item.status !== "For Sale" && item.status !== "To Let") ? 
                        (process.env.GATSBY_SITE_NAME === "John Shepherd" ?
                            <span className={`top-text ${!collections_property ? item?.officeDepartment : ""}`}>
                                {item.status}
                            </span> 
                        :
                            <span className={`top-text`}>
                                {item.status}
                            </span> 
                        )
                    : ''
                    }
                    

                    {/* {item?.images && item?.images.length > 0 &&
                        <span className="bottom-text" onClick={(e) => openLighboxMobile(e, 0)}>
                            <i className="gallery-icon"></i>
                            <span className="text">{prop_all_images?.length}</span>
                        </span>
                    } */}
                    
                    {office_depart && (office_depart.includes("LIS") || office_depart.includes("LSO") || office_depart.includes("LSQ")) &&                        
                        <span className="bottom-text dale_eddison_tag">
                            <span className="text">Dale Eddison</span>
                        </span>                        
                    }

                    {/* <SaveItem type="property" pid={property_id} userObjects={userObjects}>
                        <span className="save-item">
                            <HeartIcon /><span> Save</span><span className="saved-text">d</span>
                        </span>
                    </SaveItem> */}
                </div>

                {/* ======= List View Thumbnail Image ===== */}
                {!isGridView && item?.images && item?.images.length > 1 &&
                    <div className="sm-sales-img-wrap d-none d-xl-block">
                        {item?.images.map((img, i) => {
                            if (i > 0) {
                                return (
                                    // <a href="javascript:;" onClick={(e) => openLighboxMobile(e, i)}>
                                    <Link to={property_url} state={{ property_data:{...item, address:item.display_address}, form_type: "investment_centre", property_url: property_url, to_email_id, search_url }}>

                                        {img_type === "no_property" ?
                                            (i <= 3 &&
                                                <ImageRenderList item={item} image_url={prop_images[i]} />
                                            )
                                            :
                                            <ShowProcessedImage images={item?.images[i]} attr={{ alt: alt_text }} transforms={thumb_transforms} />
                                        }
                                    </Link>

                                    //</a>
                                    
                                )
                            }

                        })}
                    </div>
                }

                {isOpen && (prop_all_images && prop_all_images?.length != 0) && (
                    <SearchResultsImagePopup
                        propertyId={property_id}
                        propertyImage={prop_all_images}
                        photoIndex={photoIndex}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        largeSize="720x380"
                        largeSize2="570x374"
                        img_type={img_type}
                    />
                )}
            </div>

            <div className="slide-content">
                <Link to={property_url} state={{ property_data:{...item, address:item.display_address}, form_type: "investment_centre", property_url: property_url, to_email_id, search_url }}>
                    {/* <div className="h5">{item.title}</div> */}
                    <div className="content">
                        {item?.search_type === "sales" 
                            ?
                            <p className="highlight-text">{price_convert}  
                                {(item?.price_qualifier && (item?.price_qualifier !== "Offers in the region of" && item?.price_qualifier !== "Offers in excess of")) && <span className="sm-text ">{item.price_qualifier}</span> }
                            </p>
                            :                 
                            <p className="highlight-text">{pppw}  
                                <span className="sm-text ">{show_pppw ? " pw" : " pcm"}</span>
                            </p>
                        }

                    </div>

                    <div className="d-md-flex title-wrap">
                        <h3>{item.display_address}</h3>
                    </div>

                    {isGridView && 
                        <RentYieldPrice />                        
                    }

                    <div className="icon-wrapper">
                        {item.property_type && 
                        <div className="icon-wrap">
                            <span className="count prop_type">{item.property_type}</span>
                        </div>
                        }

                        {parseInt(item.bedroom) > 0 ?
                            <div className="icon-wrap">
                                <i className="icon-bed"></i>
                                <span className="count">{item.bedroom}</span>
                            </div>
                            :""
                        }

                        {parseInt(item.bathroom) > 0 ?
                            <div className="icon-wrap">
                                <i className="icon-bath"></i>
                                <span className="count">{item.bathroom}</span>
                            </div>
                            :""
                        }

                        {parseInt(item.reception) > 0 ?
                            <div className="icon-wrap">
                                <i className="icon-reception"></i>
                                <span className="count">{item.reception}</span>
                            </div>
                            :""
                        }

                        {/* {item.hasParking === true &&
                            <div className="icon-wrap">
                                <a href="javascript:;">
                                    <i className="icon-parking"></i>
                                </a>
                                <span className="count">Parking</span>
                            </div>
                        } */}

                        {/* {item.council_tax ?
                            <div className="icon-wrap">
                                <i className="icon-house"></i>
                                <span className="count">{item.council_tax}</span>
                            </div>
                            :""
                        }

                        {energyRate ?
                            <div className="icon-wrap">
                                <i className="icon-light"></i>
                                <span className="count">{energyRate}</span>
                            </div>
                            :""
                        } */}
                    </div>
                    {/* {item.search_type === "lettings" && 
                        (!isGridView ? 
                            (item.available_from && 
                                <div className="available_date">
                                    {new Date(item.available_from).valueOf() > new Date().valueOf() 
                                        ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                        : `Available Now`
                                    }
                                </div>
                            )

                        :
                            <div className="available_date">
                                {item.available_from ? 
                                    (new Date(item.available_from).valueOf() > new Date().valueOf() 
                                    ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                    : `Available Now`
                                    )
                                    : ''
                                }
                            </div>
                        )
                    } */}

                    
                    {!isGridView && <RentYieldPrice />}  

                    <p className="d-none d-xl-block short_desc">
                        <span>{item?.description?.replace(/<br\s*\/?>/gi, ' ')}</span> 
                        

                        {/* {item?.description && <ContentModule Content={item?.description} />} */}

                        {/* <div className="more-link">more</div> */}
                    </p>
                </Link>
                
                {
                    <div className="nego_details">
                        {/* <button className="btn btn-outline">
                            <i className="icon-phone"></i> Call
                        </button>
                        <button className="btn btn-outline">
                            <i className="icon-mail"></i> Email
                        </button> */}

                        {(process.env.GATSBY_BOOK_VIEWING_LINK && item?.search_type === "lettings" && agent_id) ?
                            <a href={"javascript:;"} onClick={() => {setModalShow(true); setViewingLink(book_a_viewing_link)}} data-link={book_a_viewing_link} className="btn btn-primary">
                                <i className="icon-mail"></i>
                                <span className="d-none d-xl-block">Enquire</span>
                                <span className="d-xl-none">Enquire</span>
                            </a>
                        :
                            <Link to={`/${PageLinks.book_a_viewing}/`} state={{ property_data:{...item, address:item.display_address}, form_type: "book_a_viewing", property_url: property_url, to_email_id }} className="btn btn-primary">
                                <i className="icon-mail"></i>
                                <span className="d-none d-xl-block">Enquire</span>
                                <span className="d-xl-none">Enquire</span>
                            </Link>
                        }

                        {(process.env.GATSBY_SITE_NAME !== "D. J. Alexander" || item?.search_type !== "lettings") && office_mobile && 
                            <a href={`tel:${office_mobile}`} className={`btn btn-outline ${item?.search_type}`}>
                                <i className="icon-phone"></i>
                                <span className="d-none d-xl-block">{office_mobile}</span>
                                <span className="d-xl-none">Call</span>
                            </a>
                        }
                        
                    </div>
                }
                <SaveItem type="property" pid={property_id} userObjects={userObjects}>
                    <span className="map-info save-item">
                        <HeartIcon /><span> Save</span><span className="saved-text">d</span>
                    </span>
                </SaveItem>
            </div>
        </div>
    );
};
export default ListView;